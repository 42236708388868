export class User{

    LOGINNAME: string;
    CODMARKET: string;
    LANGUAGE: string;
    FIRSTNAME: string;
    LASTNAME: string;
    EMAIL: string;
    BRANDS: string[];

    constructor(){};

}