import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
//import { FiatAppSharedModule } from 'projects/fiat/src/app/fiat-app.module';
//import { JeepAppSharedModule } from 'projects/jeep/src/app/jeep-app.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from 'src/environments/environment';
import {AuthenticationInterceptor} from './_services/interceptors/authentication.interceptor';
import {AuthGuard} from './_services/guards/auth.guard';
import {RedirectLayoutComponent} from './_component/redirect-layout/redirect-layout.component';
import {UserProfileService} from './_services/user-profile.service';
import {InlineSVGModule} from "ng-inline-svg";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  declarations: [
    AppComponent,
    RedirectLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    //FiatAppSharedModule.forRoot(),
    //JeepAppSharedModule.forRoot(),
    HttpClientModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    AuthGuard,
    UserProfileService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader( http: HttpClient ) {
  return new TranslateHttpLoader(http, `${environment.apiUrl}/labels/core/`, '' );
}
