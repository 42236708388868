import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthenticationService,
    private router : Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    var market = route.queryParams['market'];
    var ticket = route.queryParams['ticket'];
    if(!isNullOrUndefined(route.queryParams['ticket']) && !this.authService.isAuthenticated() &&  !isNullOrUndefined(route.queryParams['market']) ){
      return this.authService.authenticate(ticket, market).pipe(
        map( res => {

          if(res == true){
            const params = {... route.queryParams};
            delete params.ticket;
            delete params.market;
            this.router.navigate([], {queryParams: params});
          }
          return res;
        })
      );
    }else if (!this.authService.isAuthenticated()) {
      return this.authService.goToLogin();
    }
    return new Observable<boolean>( o => {o.next(true)});
  }
}
