import { Injectable } from '@angular/core';
import {HttpRequest,HttpHandler,HttpEvent,HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { isNullOrUndefined } from 'util';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private notAuthResources = [
    '/auth', '/labels'
  ];

  constructor(
    private authService : AuthenticationService
  ) {}

  private withoutCredentialsResource(url : string) : boolean{
    const c =  this.notAuthResources.find( el => {
      return url.indexOf(el) > 0;
    });
    return c != null;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    if(this.withoutCredentialsResource(request.url)){
      return next.handle(request);
    }

    if(!this.authService.isAuthenticated){
      this.authService.goToLogin();
      return next.handle(request);
    }

    let token = this.authService.getToken();
    if (!isNullOrUndefined(token)) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    return next.handle(request);
  }
}
