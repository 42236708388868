import { Injectable } from '@angular/core';
import { User } from '../_models/User';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private _user = new BehaviorSubject<User>(null);
  public user = this._user.asObservable().pipe( filter ( value => value != null));

  constructor(
    private localeSrv : LocaleService
  ) {
    this.user.subscribe(user =>
      {

        console.log(user);
        this.localeSrv.configureLanguage(user.LANGUAGE);
        // this.localeSrv.configureLanguage('en');
      }
    );
  }

  next(user : User){
    this._user.next(user);
  }

  getValue() : User{
    return this._user.getValue();
  }


}
