import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Constants } from '../Constants';
import { CookieStoragerService } from './storager/cookie-storager.service';
import { User } from '../_models/User';
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private jwtHelper: JwtHelperService;
  readonly USER_KEY : string = 'user';

  constructor(
    private router: Router,
    private http: HttpClient,
    private storager : CookieStoragerService,
    private userService : UserProfileService
    ) {
      this.jwtHelper = new JwtHelperService();
    }

  isAuthenticated(): boolean {
    const rawToken = this.getToken();
    // alert(isNullOrUndefined(rawToken));
    // this.jwtHelper.getTokenExpirationDate(rawToken);
    if(isNullOrUndefined(rawToken)){
      return false;
    }

    if(this.jwtHelper.isTokenExpired(rawToken)){
      this.storager.removeElement(this.USER_KEY);
      return false;
    }

    //in this moment the userprofile service will be initialized by the cookie information
    if(this.userService.getValue() == null){
      this.userService.next(this.convertToUser(rawToken));
    }

    return true;
  }

  goToLogin(): Observable<boolean> {
    if(environment.loginUrl){
      this.router.navigate(Constants.Routing.LOGIN.routerLink);
    }
    return new Observable<boolean>( o => o.next(false));
  }

  authenticate(ticket : string, market: string) : Observable<boolean>{
    ticket = encodeURIComponent(ticket);
    return this.http.post<any>(`${environment.apiUrl}/auth/`, { "market": market, AuthTicket: ticket }

    ).pipe(
      map(
        res => {
          const jwt = res.JWTToken;
          const expirationDate = this.jwtHelper.getTokenExpirationDate(jwt);

          this.userService.next(this.convertToUser(jwt));

          this.storager.setElement(this.USER_KEY, res, false, expirationDate);
          return true;
        }
      ),
      catchError(err => {
          return this.goToLogin();
      }),
    );

  }

  getToken(): string{
    const usr = this.storager.getElement(this.USER_KEY);
    return !isNullOrUndefined(usr) ? usr.JWTToken : null;
  }

  private convertToUser(jwtToken : string) : User{
    const payLoad = this.jwtHelper.decodeToken(jwtToken);
    const identity = payLoad.identity;

    let user = new User();
    user.BRANDS = identity.BRANDS;
    user.CODMARKET = identity.CODMARKET;
    user.LANGUAGE = identity.LANGUAGE;
    user.FIRSTNAME = identity.FIRSTNAME;
    user.LASTNAME = identity.LASTNAME;
    user.EMAIL = identity.EMAIL;
    user.BRANDS = identity.BRANDS;

    return user;
  }

}
