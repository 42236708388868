export class Constants {

  public static FIAT_RADIX = 'fiat';
  public static JEEP_RADIX = 'jeep';

  public static TITLE_INFO = 'INFO!';
  public static TITLE_ERROR = 'ERROR!';
  public static TITLE_WARNING = 'WARNING!';
  public static TITLE_SUCCESS = 'SUCCESS!';
  public static TITLE_OFFLINE = 'IS OFFLINE!';

  static Routing = class {
    public static DASHBOARD = {label: 'Dashboard', path: 'dashboard', routerLink: ['/dashboard']};
    public static HOME = {label: 'Home', path: 'home', routerLink: ['/home']};
    public static FIAT = {label: 'Fiat App', path: 'fiat', routerLink: ['/fiat']};
    public static FIAT_ASSESSMENT = {label: 'Fiat Assessment Client', path: 'assessment', routerLink: ['/assessment']};
    public static FIAT_SIMULATOR = {label: 'Fiat Simulator', path: 'simulator', routerLink: ['/simulator']};
    public static JEEP = {label: 'Jeep App', path: 'jeep', routerLink: ['/jeep']};
    public static JEEP_ASSESSMENT = {label: 'Jeep Assessment Client', path: 'assessment', routerLink: ['/assessment']};
    public static LOGIN = {label: 'Login', path: 'login', routerLink: ['/login']};
    public static JEEP_SIMULATOR = {label: 'Fiat Simulator', path: 'simulator', routerLink: ['/simulator']};
    public static SUMMARY = {label: 'Summary', path: 'summary', routerLink: ['/summary']};
    public static SIMULATIONEXPORT = {label: 'SimulationExport', path: 'simulation-export', routerLink: ['/simulation-export']};


  }


}
