import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RedirectGuard } from './_services/guards/redirect-guard';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './_services/guards/auth.guard';
import { Constants } from './Constants';
import { RedirectLayoutComponent } from './_component/redirect-layout/redirect-layout.component';


const routes: Routes = [
  //{path: Constants.Routing.FIAT.path, loadChildren: () => import('projects/fiat/src/app/fiat-app.module').then(mod => mod.FiatAppSharedModule)},
  //{path: Constants.Routing.JEEP.path, loadChildren: () => import('projects/jeep/src/app/jeep-app.module').then(mod => mod.JeepAppSharedModule)},
  {path : Constants.Routing.FIAT.path, component: RedirectLayoutComponent, canActivate: [RedirectGuard], data : {externalUrl : environment.fiat.redirect_url}},
  {path : Constants.Routing.JEEP.path, component: RedirectLayoutComponent, canActivate: [RedirectGuard], data : {externalUrl : environment.jeep.redirect_url}},
  {path : Constants.Routing.LOGIN.path, component: RedirectLayoutComponent, canActivate:[RedirectGuard], data : {externalUrl : environment.loginUrl}},
  {path: Constants.Routing.DASHBOARD.path, canActivate: [AuthGuard], loadChildren : () => import ('./_layout/dashboard/dashboard.module').then(mod => mod.DashboardModule)},
  {path: '', redirectTo: Constants.Routing.DASHBOARD.path, pathMatch: 'full' }
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
